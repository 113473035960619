// import pic1 from "../assets/pic1.jpg";
import React, { useState }  from "react"
import { FaShoppingCart } from "react-icons/fa";
import { IoRestaurant } from "react-icons/io5";
import { FaHotel } from "react-icons/fa6";
import { MdOutlinePointOfSale } from "react-icons/md";
import { Link } from "react-router-dom"
import { MdFavorite } from "react-icons/md";
import { FaCommentDots } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoReorderFourOutline } from "react-icons/io5";
import { BiScan } from "react-icons/bi";
import { RiSecurePaymentFill } from "react-icons/ri";
import { MdShoppingCart } from "react-icons/md";
import { GiCook } from "react-icons/gi";
import { FaFileAlt } from "react-icons/fa";
import { MdInventory } from "react-icons/md";
import { MdAccountBalance } from "react-icons/md";
import { MdAccountTree } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import { MdPointOfSale } from "react-icons/md";
import { IoReorderFour } from "react-icons/io5";
import { HiComputerDesktop } from "react-icons/hi2";
import { RiComputerFill } from "react-icons/ri";
import { MdManageAccounts } from "react-icons/md";
import { RiHomeOfficeFill } from "react-icons/ri";
import { IoPeople } from "react-icons/io5";
import { FaLongArrowAltDown } from "react-icons/fa";
import { motion } from "framer-motion";
import {
  RadialTextGradient
} from "react-text-gradients-and-animations";



const cardsData = [
  {
    image: <IoRestaurant  size={20} className="mx-auto" />,
    title: "Cranesoft E-menu",
    description:
      "Our digital menu solution, simplifying menu management and enhancing the dining experience for restaurants, bars, lounges and hotels.",
    link: "/emenu",

  },
  {
    // image: "https://via.placeholder.com/300",
    image: <FaHotel  className="mx-auto" size={20} />,
    title: "Cranesoft Hotel Software",
    description:
      "CraneSoft Hotel Management Software has been a cornerstone in the hospitality industry, offering a comprehensive and integrated platform that empowers hotels to streamline their operations with unparalleled efficiency.",
    link: "/hotel-software",

  },
  {
    image: <FaShoppingCart size={20} className="mx-auto"/>,
    title: "Cranesoft ERP",
    description:
      "Cranesoft ERP for retail businesses is a software designed to enhance the day-to-day transactions of your business, increase profit, minimise expenditure and improve customer services by increasing operational efficiency",
    link: "/respos-software",

  },
  {
    image: <MdOutlinePointOfSale size={60} className="mx-auto"/>,
    title: "Restaurant POS",
    description:
      "Customers can easily add items to their cart and modify their orders as needed.",
    link: "/erp-software",

  },


  
];
const Menu = [
  {
    id: 1,
    name: "Cranesoft E-menu",
    href: "/emenu",
    subMenu: [
      {
        id: 1,
        title: "Favorites Tab",
        icon: <MdFavorite size={20} color="" />,
        text: "Customers can add their favorite items to the favorites list.",
      },
      {
        id: 2,
        title: "Customer Review",
        icon: <FaCommentDots size={20} color="" />,
        text: "Customers can leave their reviews on the Restaurant anonymously.",
      },
      {
        id: 3,
        title: "Call a Waiter",
        icon: <FaPhoneAlt size={20} color="" />,
        text: "Customers can call on a Waiter via the E-menu to pay their bill.",
      },
      {
        id: 4,
        title: "View Order",
        icon: <IoReorderFourOutline size={20} color="" />,
        text: "Customers can view their order after placing it.",
      },
      { id: 5, title: "Easy Scan", icon: <BiScan size={20} color="" />,
      text: "Customers can easily access the E-menu by scanning a QR code.", },
      {
        id: 6,
        title: "Payment Integration",
        icon: <RiSecurePaymentFill size={20} color="" />,
        text: "Customers have the option to make payments for their orders directly through the E-menu.",
      },
      {
        id: 7,
        title: "Online Delivery",
        icon: <MdShoppingCart size={20} color="" />,
        text: "Customers outside the Restaurant can request for delivery",
      },
      { id: 8, title: "Cook Time", icon: <GiCook size={20} color="" />,
      text: "Customers can see how long it takes for their order to be ready.", },
      {
        id: 9,
        title: "Report Management",
        icon: <FaFileAlt size={20} color="" />,
        text: "You can manage reports for Staff etc.",
      },
    ],
  },
  {
    id: 2,
    name: "Cranesoft HMS",
    href: "/hotel-software",
    subMenu: [
      {
        id: 1,
        title: "Guest Management",
        icon: <RiHomeOfficeFill size={20}  />,
        text: "Designed to manage all aspect of Guest detail, from arrival to departure."
      },
      {
        id: 2,
        title: "Point of Sales",
        icon: <MdPointOfSale size={20}  />,
        text: "Enables seamless transactions for meal and drinks.",
      },
      {
        id: 3,
        title: "Inventory",
        icon: <MdInventory size={20} />,
        text: "Provides a robust system for updating and handling food and drink items purchased ",
      },
      {
        id: 4,
        title: "Accounting",
        icon: <MdAccountBalance size={20} />,
        text: "Comprehensive features for managing accounts payable and receivable, financial reporting, etc.",
      },
      { id: 5, title: "HRM", icon: <IoPeople size={20} />,
      text: "Comprehensive solution designed to streamline HR processes for hotels and hospitality businesses." },
     
    ],
  },
  {
    id: 3,
    name: "Cranesoft ERP",
    href: "/erp-software",
    subMenu: [
      {
        id: 1,
        title: "Inventory",
        icon: <MdInventory size={20} color="" />,
        text: "Provides a robust system for updating and handling items purchased",
      },
      {
        id: 2,
        title: "Account",
        icon: <MdAccountBalance size={20}  />,
        text: "Comprehensive features for managing accounts payable and receivable, financial reporting, etc.",
      },
      {
        id: 3,
        title: "Management Report",
        icon: <MdAccountTree size={20}  />,
        text: "You can manage reports for Staff etc.",
      },
      {
        id: 4,
        title: "Payroll & Staff Information",
        icon: <BiSolidReport size={20} />,
        text: "Staff Profile registration, Salary preparation, Staff categorization etc.",
      },
    
    ],
  },
  {
    id: 4,
    name: "Cranesoft POS",
    href: "/respos-software",
    subMenu: [
      {
        id: 1,
        title: "Manage Order",
        icon: <IoReorderFour size={20} />,
        text: "Handling your orders with the POS.",
      },
      {
        id: 2,
        title: "GUI POS Interface",
        icon: <HiComputerDesktop size={20} />,
        text: "This interface serves as the central hub for placing and managing orders for food and drinks.",
      },
      {
        id: 3,
        title: "POS Waiter Screen",
        icon: <RiComputerFill size={20} />,
        text: "Waiters interface to accept, reject or cancel orders.",
      },
      {
        id: 4,
        title: "Management Shift",
        icon: <MdManageAccounts size={20} />,
        text: "Handles Shift for staff.",
      },
      { id: 5, title: "Sales Summary Report", icon: <FaFileAlt size={20} />,
      text: "Daily report for sales recorded", },
      {
        id: 6,
        title: "Inventory",
        icon: <MdInventory size={20} />,
        text: "Provides a robust system for updating and handling food and drink items purchased.",
      },
     
    ],
  },
];


const Features = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <motion.section className=" py-24" id="products"
      initial={{ y: -100 }} // Initial position (off-screen to the left)
      animate={{ y: 0 }}    // Final position (on-screen)
      transition={{ delay: 1 }} // Delay the animation by 1 second
    >
      <RadialTextGradient
      className="text-5xl text-center flex items-center justify-center pb-4 font-bold "
shape={ "circle" }
position={ "center" }
colors={[ "#15803D" , "#757AB1", "#EB5C67" ]}
animate={ true }
animateDirection={ "horizontal" }
animateDuration={ 6 }
textAlign="center"

>
Our Solutions    
</RadialTextGradient>
      <p className="text-center text-xl p-8 lg:w-[50%] mx-auto">Discover a business solution tailored to your needs and propel your success to new heights with our time-tested expertise. Find out how we can elevate your business today.</p>
    <div className="flex flex-col justify-center items-center">
      <div className=" grid grid-cols-2 md:grid-cols-4 gap-2 mb-4">
        {Menu.map((item, index) => (
          <div key={item.id} onClick={() => handleTabClick(index)} className={`cursor-pointer text-center bg-[#E9F8F3] p-4 font-semibold ${index === activeTab ? 'bg-green-700 text-white' : 'text-[#15803D] bg-[#E9F8F3] '}`}>
            {item.name}
          </div>
        ))}
      </div>
      <div className=" grid lg:grid-cols-3 md:w-[80%] mt-8 lg:gap-12 lg:items-center lg:justify-center ">
      {Menu[activeTab].subMenu.map((subItem, index) => (
  <a key={subItem.id} href={Menu[activeTab].href}>
    <div key={subItem.id} className={`flex  lg:w-[350px] w-[80vw] px-4 py-4 pl-4 flex-row gap-4 mb-2 h-[110px] transition duration-300 ease-in-out hover:bg-[#E9F8F3] cursor-pointer ${index % 2 !== 0 ? 'bg-[#d4ecd4]' : 'bg-[#E9F8F3] hover:bg-[#d4ecd4]'}`}>
      <div className="rounded-lg bg-[#15803D]/10 h-[50px] p-2">
        <div className="rounded-lg p-2">
          {subItem.icon}
        </div>
      </div>
      <div>
        <span className="font-semibold ">{subItem.title}</span>
        <p className="text-sm">{subItem.text}</p>
      </div>
    </div>
  </a>
))}

      </div>
    </div>
    </motion.section>
  );
};

export default Features;
