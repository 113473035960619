import React from 'react'
// import Capture from "../assets/Capture.png"
// import fpos from "../assets/fpos.JPG"
// import h from "../assets/h.JPG"
import foffice from "../assets/foffice.png";
import { FaCheck } from "react-icons/fa6";
import { motion } from "framer-motion"
import inv from "../assets/inventory.png"
import account2 from "../assets/account2.PNG"
import cpos from "../assets/pos.jpg"


const Modules = () => {
  return (
    <section className="w-full ">
  

      <div className='bg-[#339c47] py-12'>
      <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto text-white max-w-[600px] ">
      <h1 className='text-center font-bold mb-12 text-4xl'>Inventory Management Module</h1>
      <p className='text-lg font-light mb-8 text-center'>
CraneSoft's Inventory Management module provides a robust system for updating and handling food and drink items purchased in restaurants, bars, and lounges. It allows businesses to track inventory levels, manage stock orders, and monitor costs effectively. The module offers features such as real-time inventory updates, and detailed reporting, enabling businesses to optimize their inventory processes and minimize waste.
</p>
      <div className='md:flex '>

        <div className="md:w-[50%] mb-8 px-8 mx-auto">
  <motion.ul className="text-lg font-light text-white flex flex-col items-start"
  initial={{ opacity: 0, x: 200 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 1 }}>
    <li className="flex justify-center items-center">
      <FaCheck className="mr-2 text-2xl" />
      <span>Manage Purchase e.g Purchase Reports, Purchase History, Purchase Order</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2" />
      <span>Vendors Account</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2" />
      <span>Stock Request List</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2" />
      <span>Bin Card/ Stock Movement</span>
    </li>
  
   
  </motion.ul>
</div>
        <motion.div 
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="md:w-[50%] items-center justify-center flex px-8 ">
          
        <img src={inv} className=' rounded-lg'/>
        </motion.div>

      </div>
      </div>
      </div>
      
    
      < div className='bg-white py-12'>
        <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto max-w-[600px] ">
          <h1 className='text-center font-bold mb-12 text-4xl'> Accounting Module</h1>
          <p className='text-lg font-light mb-8 text-center'>CraneSoft's Accounting module offers comprehensive features for managing accounts payable and receivable, budgeting, financial reporting, and more. The module integrates seamlessly with other CraneSoft modules, providing a unified platform for tracking financial transactions and ensuring compliance with accounting standards. With its user-friendly interface and robust functionality, the Accounting module enables businesses to maintain accurate financial records and make informed decisions to drive growth and profitability.</p>
          <div className='md:flex'>
  
  
  <div className="md:w-[50%] mb-8 px-8 mx-auto">
  <motion.ul className="text-lg font-light text-green flex flex-col items-start"
  initial={{ opacity: 0, x: -200 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 1 }}>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Records of Income & Expenditures</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Posting of Expense</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Trial Balance</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>P & L</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Balance Sheet</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Cash and Bank Statement</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Bank Reconciliation</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Cash and Bank Transfer</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Creditor and Debtors Ledger</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Auditing of POS</span>
    </li>
  </motion.ul>
</div>

  <motion.div className="md:w-[50%] items-center justify-center flex px-8 md:order-first"
   initial={{ opacity: 0, x: -200 }}
   animate={{ opacity: 1, x: 0 }}
   transition={{ duration: 0.8 }}>
    <img src={account2} className=' w-80vw rounded-lg'/>
  </motion.div>
</div>
        </div>
      </div>

      <div className='bg-[#339c47] py-12'>
      <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto text-white max-w-[600px] ">
      <h1 className='text-center font-bold mb-12 text-4xl'>Payroll and Staff Information</h1>
      <p className='text-lg font-light mb-8 text-center'>CraneSoft's Human Resource Management (HRM) module is a comprehensive solution designed to streamline HR processes for hotels and hospitality businesses. It offers a wide range of features to manage employee information, track attendance, handle payroll, and facilitate performance reviews. </p>
      <div className='md:flex '>
        <div className="md:w-[50%] mb-8 px-8 mx-auto flex items-center">
        <ul style={{listStyleType: 'disc', marginLeft: '1em'}} className='text-lg font-light'>
        
           <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Salary Preparation</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Staff Profile Registration</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Monthly Payroll</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Staff Pay Schedule</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Staff Pay Slip</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Staff Categorization (Class, Level & Steps)</span>
    </li>
          
        </ul>
        </div>
        <div className="md:w-[50%] items-center justify-center flex px-8 ">
        <img src={foffice} className=' rounded-lg'/>
        </div>

      </div>
      </div>
      </div>      


    </section>
  )

}

export default Modules