import Hero from "../components/Hero.jsx"
import Advantages from "../components/Advantages.jsx"
import Modules from "../components/Modules.jsx"
import Pricing from "../components/Pricing.jsx"

import React from 'react'

const Home = () => {
  return (
    <div>
        <Hero />
        <Advantages />
        <Modules />
        <Pricing />
        
    </div>
  )
}

export default Home