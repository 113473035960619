import React from 'react'
import Capture from "../assets/Capture.png"
import h from "../assets/h.JPG"
import foffice from "../assets/foffice.png";
import { FaCheck } from "react-icons/fa6";
import { motion } from "framer-motion"
import inv from "../assets/invhotel.png"
import account2 from "../assets/account2.PNG"
import cpos from "../assets/pos.jpg"


const Modules = () => {
  return (
    <section className="w-full ">
       <div className='bg-[#339c47] py-12'>
      <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto max-w-[600px] ">
      <h1 className='text-center font-bold mb-8 text-4xl text-white'>Guest Management Module</h1>
      <p className='text-lg font-light mb-6 text-white text-center'>
      The Guest Management module of Cranesoft Hotel Management Software is designed to manage every aspect of the Guest detail, from arrival to departure, ensuring a seamless experience and operational excellence.
</p>
      <div className='md:flex '>

     
        <div className="md:w-[50%] mb-8 px-8 mx-auto">
  <motion.ul className="text-lg font-light  text-white flex flex-col items-start"
  initial={{ opacity: 0, x: 200 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 1 }}>
    <li className="flex justify-center items-center">
      <FaCheck className="mr-2 " />
      <span>Creating Room Tariff and Room Types</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Guest Registration</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Guest Account/Financial Records</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Guest Check-In & Check-out Records</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Issuance of Deposit Receipt (Cash, Cheque, POS)</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Room Status Report</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Reservation Record</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>City(Individual) Ledger/Corporate Ledger</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Shift Report</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Financial Activity Report</span>
    </li>
   
  </motion.ul>
</div>
        <motion.div 
          initial={{ opacity: 0, x: 200 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        className="md:w-[50%] items-center justify-center flex px-8 ">
        <img src={foffice} className=' rounded-lg'/>
        </motion.div>

      </div>
      </div>
      </div>
      
      < div className=' py-12'>
        <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto  max-w-[600px] ">
          <h1 className='text-center font-bold mb-8 text-gray-700 text-4xl'> Restaurant Point of Sales (POS) Module</h1>
          <p className='text-lg font-light mb-8 text-center text-gray-700'>Cranesoft's Restaurant POS module enables seamless transactions for meals and drinks, ensuring accuracy and efficiency in every transaction. The module is equipped to handle various aspects of restaurant management; bars, lounges including order processing,  and payment processing, providing a comprehensive solution for hospitality businesses. </p>
          <div className='md:flex'>
  
  
  <div className="md:w-[50%] mb-8 px-8 mx-auto">
  <motion.ul className="text-lg font-light text-gray-700 flex flex-col items-start"
  initial={{ opacity: 0, x: -200 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 1 }}>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Placing of Order Ticket for Food & Drinks</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Sales and Issuance of Receipt for Food & Drinks</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Item/Product Registration</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Loading of Sales Stock</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Monitoring of Sales Stock Reports</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Sales Summary/Shift Reports</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Financial Activity Report</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Split Bill</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Move and Merge Order</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Waiters Screen</span>
    </li>
  </motion.ul>
</div>

  <motion.div className="md:w-[50%] items-center justify-center flex px-8 md:order-first"
   initial={{ opacity: 0, x: -200 }}
   animate={{ opacity: 1, x: 0 }}
   transition={{ duration: 0.8 }}>
    <img src={cpos} className=' w-80vw rounded-lg'/>
  </motion.div>
</div>
        </div>
      </div>

      <div className='bg-[#339c47] py-12 text-white'>
      <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto max-w-[600px] ">
      <h1 className='text-center font-bold mb-8 text-4xl'>Inventory Management Module</h1>
      <p className='text-lg font-light mb-8 text-center'>
Cranesoft's Inventory Management module provides a robust system for updating and handling food and drink items purchased in restaurants, bars, and lounges. It allows businesses to track inventory levels, manage stock orders, and monitor costs effectively. The module offers features such as real-time inventory updates, and detailed reporting, enabling businesses to optimize their inventory processes and minimize waste.
</p>
      <div className='md:flex '>

        <div className="md:w-[50%] mb-8 px-8 mx-auto">
  <motion.ul className="text-lg font-light text-green flex flex-col items-start"
  initial={{ opacity: 0, x: 200 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 1 }}>
    <li className="flex justify-center items-center">
      <FaCheck className="mr-2 text-2xl" />
      <span>Manage Purchase e.g Purchase Reports, Purchase History, Purchase Order</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Vendors Account</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Adjust Inventory</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Requisition Book-Out/Returns</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Bin Card/ Stock Movement</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Local Purchase Order (LPO)</span>
    </li>
   
  </motion.ul>
</div>
        <motion.div 
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="md:w-[50%] items-center justify-center flex px-8 ">
          
        <img src={inv} className=' rounded-lg'/>
        </motion.div>

      </div>
      </div>
      </div>
      
  

      < div className=' py-12'>
        <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto  max-w-[600px] ">
          <h1 className='text-center font-bold mb-8 text-4xl'> Accounting Module</h1>
          <p className='text-lg font-light mb-8 text-center'>Cranesoft's Accounting module offers comprehensive features for managing accounts payable and receivable, budgeting, financial reporting, and more. The module integrates seamlessly with other Cranesoft modules, providing a unified platform for tracking financial transactions and ensuring compliance with accounting standards. With its user-friendly interface and robust functionality, the Accounting module enables businesses to maintain accurate financial records and make informed decisions to drive growth and profitability.</p>
          <div className='md:flex'>
  
  
  <div className="md:w-[50%] mb-8 px-8 mx-auto">
  <motion.ul className="text-lg font-light text-green flex flex-col items-start"
  initial={{ opacity: 0, x: -200 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 1 }}>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Records of Income & Expenditures</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Posting of Expense</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Trial Balance</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>P & L</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Balance Sheet</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Cash and Bank Statement</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Bank Reconciliation</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Cash and Bank Transfer</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Creditor and Debtors Ledger</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Auditing of POS</span>
    </li>
  </motion.ul>
</div>

  <motion.div className="md:w-[50%] items-center justify-center flex px-8 md:order-first"
   initial={{ opacity: 0, x: -200 }}
   animate={{ opacity: 1, x: 0 }}
   transition={{ duration: 0.8 }}>
    <img src={account2} className=' w-80vw rounded-lg'/>
  </motion.div>
</div>
        </div>
      </div>

      <div className='bg-[#339c47] py-12 text-white'>
      <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto  max-w-[600px] ">
      <h1 className='text-center font-bold mb-8 text-4xl'>HRM</h1>
      <p className='text-lg font-light mb-8 text-center'>Cranesoft's Human Resource Management (HRM) module is a comprehensive solution designed to streamline HR processes for hotels and hospitality businesses. It offers a wide range of features to manage employee information, track attendance, handle payroll, and facilitate performance reviews. </p>
      <div className='md:flex '>
        <div className="md:w-[50%] mb-8 px-8 mx-auto flex items-center">
        <ul style={{listStyleType: 'disc', marginLeft: '1em'}} className='text-lg font-light'>
    
           <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Salary Preparation</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Staff Profile Registration</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Monthly Payroll</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Staff Pay Schedule</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2" />
      <span>Staff Pay Slip</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 " />
      <span>Staff Categorization (Class, Level & Steps)</span>
    </li>
          
        </ul>
        </div>
        <div className="md:w-[50%] items-center justify-center flex px-8 ">
        <img src={foffice} className=' rounded-lg'/>
        </div>

      </div>
      </div>
      </div>      


      < div className=' py-12'>
        <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto  max-w-[600px] ">
          <h1 className='text-center font-bold mb-8 text-4xl'> Reporting System</h1>
          <p className='text-lg font-light mb-8 text-center'>
Our reporting system is designed to streamline Business Management, providing essential insights and tools. It offers detailed analysis and simplifies Financial Management, Housekeeping, Guest Management etc, empowering you to make informed decisions and drive growth. </p>
          <div className='md:flex'>
  
  
  <div className="md:w-[50%] mb-8 px-8 mx-auto">
  <motion.ul className="text-lg font-light text-green flex flex-col items-start justify-center"
  initial={{ opacity: 0, x: -200 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 1 }}>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span >Management Report; Purchase, Tax etc</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Accounting Report; Cash book etc</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Inventory Report</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Housekeeping Report; Room status etc.</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Front office Report; Guest and Reservation History</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Purchase History</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Sales Summary Report</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Cashier Shift Report</span>
    </li>
  </motion.ul>
</div>

  <motion.div className="md:w-[50%] items-center justify-center flex px-8 md:order-first"
   initial={{ opacity: 0, x: -200 }}
   animate={{ opacity: 1, x: 0 }}
   transition={{ duration: 0.8 }}>
    <img src={account2} className=' w-80vw rounded-lg'/>
  </motion.div>
</div>
        </div>
      </div>



    </section>
  )

}

export default Modules