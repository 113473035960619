import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const CompanyMap = () => {

  return (
  <iframe
  title='Google Maps'
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.4381398758123!2d3.359205875620854!3d6.592339022358261!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b92411f115555%3A0xd2ca2650624830c2!2s1b%20Church%20St%2C%20Opebi%2C%20Ikeja%20101233%2C%20Lagos!5e0!3m2!1sen!2sng!4v1713872612107!5m2!1sen!2sng" 
  width="800" 
  height="600" 
  style={{ border:0, width: '70vw', margin: '50px auto' }}
  allowfullscreen="" 
  loading="lazy" 
  referrerpolicy="no-referrer-when-downgrade">

  </iframe>
  );
};

export default CompanyMap;
