import Hero from "../components/Hero.jsx"
import About from "../components/About.jsx"
import Modules from "../components/Modules.jsx"
import Contact from "../components/Contact.jsx"
// import Footer from "../components/Footer.jsx"
import Partners from '../../../../components/Partners'
import Advantages from "../components/Advantages.jsx"
import RequestForm from "../components/RequestForm.jsx"
import Pricing from "../components/Pricing.jsx"


import React from 'react'

const Home = () => {
  return (
    <div>
        <Hero />
        <About />
        <Advantages />
        <Modules />
        <Partners backGroundColor='#E9F8F3' />
        <Pricing />
        {/* <Contact /> */}
    </div>
  )
}

export default Home