import React, { useEffect, useState } from 'react';
import {
  RadialTextGradient
} from "react-text-gradients-and-animations";
import { motion } from "framer-motion"

const stats = [
  { id: 1, name: 'Happy Clients', value: 100, animate: {y: 50}, transition: { ease: "easeOut", duration: 2 } },
  { id: 2, name: 'Softwares Deployed', value: 4, animate: {y: 50}, transition: { ease: "easeOut", duration: 4 } },
  { id: 3, name: 'New Features Added', value: 10, animate: {y: 50}, transition: { ease: "easeOut", duration: 6 } },
  { id: 3, name: 'Experts', value: 20, animate: {y: 50}, transition: { ease: "easeOut", duration: 8 } },
  { id: 3, name: 'States', value: 30, animate: {y: 50}, transition: { ease: "easeOut", duration: 10 } },
];



const AnimatedCounter = ({ value }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < value) {
        setCount((prevCount) => prevCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 100); // Change the interval time as needed

    return () => clearInterval(interval);
  }, [count, value]);

  return <span>{count}</span>;
};

export default function Example() {
 
   
  return (
    <div className="bg-white py-12 pb-24 border shadow-2xl">
      <RadialTextGradient
      className="text-5xl text-center flex items-center justify-center pb-12 font-bold "
shape={ "circle" }
position={ "center" }
colors={[ "#15803D" , "#757AB1", "#EB5C67" ]}
animate={ true }
animateDirection={ "horizontal" }
animateDuration={ 6 }
textAlign="center"

>
     At a Glance
    
</RadialTextGradient>
    
      <div className="mx-auto max-w-7xl px-6 lg:px-32 ">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-5 ">
          {stats.map((stat) => (
            <motion.div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4 border py-8 w-full bg-[#E9F8F3] rounded-lg transform transition-transform hover:scale-105"
            initial={{ scale: 1 }}
            animate={stat.animate}
  transition={stat.transition}>
              <dt className=" text-gray-600 font-semibold">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-[#339C47] sm:text-5xl">
                <AnimatedCounter value={stat.value} />+
              </dd>
            </motion.div>
          ))}
        </dl>
      </div>
    </div>
  );
}
