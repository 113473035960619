

import React from 'react'
import { FaHotel } from 'react-icons/fa6'
import {
  RadialTextGradient
} from "react-text-gradients-and-animations";
import { SiGoogletagmanager } from "react-icons/si";
import { PiLinkSimpleBold } from "react-icons/pi";
import { FaFileCode } from "react-icons/fa6";
import { IoTime } from "react-icons/io5";
import { RiCustomerService2Fill } from "react-icons/ri";

const Advantages = () => {

  const Services = [
    {
      id: 1,
      icon: <SiGoogletagmanager />,
      title: 'Safe and Secure',
      text: "Our softwares are protected from unauthorized access and fraud. It helps your business to protect their data and assets."
    },
    {
      id: 2,
      icon: <PiLinkSimpleBold />,
      title: 'Easy to Use',
      text: "Cranesoft Softwares are user-friendly; easy to learn and use. It is also scalable, so your business can easily add new users and modules as your needs grow."
    },
    {
      id: 3,
      icon: <FaFileCode />,
      title: 'Cost Effective',
      text: "It helps businesses to reduce costs by automating tasks, streamlining processes, and improving visibility into operations. "
    },
    {
      id: 4,
      icon: <IoTime />,
      title: 'Reliable',
      text: "It automatically backs up data, so businesses can always access their information in case of a disaster."
    },
    {
      id: 5,
      icon: <RiCustomerService2Fill />,
      title: 'Support Efficiency',
      text: "We have a team of experienced and professionally qualified support staff to provide you with bespoke support services."
    },
    {
      id: 6,
      icon: <FaHotel />,
      title: 'Flexibility',
      text: "Customizable to fit your unique business processes, integrates with existing software and data and future-proof with the ability to adapt to changing business needs."
    },
  ]

  return (
    <div className='bg-gradient-to-tl from-white to-green-100 '>
      <div className='w-[80vw] mx-auto  '>
<RadialTextGradient
      className='text-center flex items-center justify-center font-bold text-4xl pt-12 '
shape={ "circle" }
position={ "center" }
colors={[ "#15803D" ,  "#757AB1", "#EB5C67" ]}
animate={ true }
animateDirection={ "horizontal" }
animateDuration={ 6 }
textAlign="center"

>
Why Choose Us
    
</RadialTextGradient>
  {/* <p className='text-center text-lg font-semibold lg:w-[60%] pt-6 mx-auto '>Cranesoft focuses on the exact requirements of the clients. We design and develop the best and most advanced software for all the types of businesses.</p> */}
  <div className='py-24 w-[80vw] grid lg:grid-cols-3 justify-center gap-4'>
  {Services.map((item, index) => (
    <div key={index} className="">
      <div className="md:max-w-80 max-w-[300px] bg-white h-96 overflow-hidden shadow-lg mb-8  transform transition-transform hover:scale-105 px-4">
        <p className="text-4xl flex items-center justify-center mt-16 rounded-lg">{item.icon}</p>
        <p className="text-xl text-center text-[#339C47] py-4 font-semibold ">{item.title}</p>
        <h3 className='text-center '>{item.text}</h3>
      </div>
    </div>
  ))}
</div>



</div>
    </div>
  )
}

export default Advantages