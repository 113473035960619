import React from 'react'
// import about from "../assets/about.jpg"
import about from "../assets/about1.jpg"

const About = () => {
  return (
    <div className='lg:flex md:w-[90vw] gap-4 border-t px-4 py-24 md:px-12 lg:mx-auto'>
        <div className='flex flex-col gap-4 lg:w-[50%]'>
            <h1 className='text-[#339C47] text-xl font-bold'>Who We Are</h1>
            <p className='text-2xl font-semibold'>

            Revolutionizing Business, Delighting Customers: <span className='text-[#339C47] text-4xl'>Our Commitment to Innovation.</span> 
            </p>
            <p className='text-lg'>
            We are a leading provider of Software Development and ICT consultancy services. Our focus is on creating user-centered solutions that help clients achieve their goals and drive business growth. With years of experience, we use a systematic approach to ensure successful implementation and smooth transition to long-term operations.  
            </p>
            <a href='/about-us'><button className=' py-4 px-6 border  bg-[#339C47] hover:bg-green-600 transition duration-300 ease-in-out text-white mb-8'>More About Us</button></a>
            
        </div>
        <div className='lg:w-[50%] mx-auto'>
        <img src={about} className='rounded-lg h-[400px] w-full lg:ml-8' />
           
            
        </div>
    </div>
  )
}

export default About