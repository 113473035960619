import React from 'react'
import Footer from '../../components/Footer'
import Stats from "../../components/Stats"
import Hero from "../../components/Hero"
import Features from "../../components/Features"
import Advantages from '../../components/Advantages'
import Partners from '../../components/Partners'
import Contact from '../../components/Contact'
import About from '../../components/About'
import Industry from '../../components/Industry'

// import Why from "../../components/Why"

const Home = () => {
  return (
    <div>
    <Hero />
    <Stats />
    <Features />
    <Advantages />
    <About />
    <Industry />
    <Partners />
  
    {/* <Contact /> */}
    </div>

  )
}

export default Home