import { useState, useEffect } from "react";
import { useRef } from "react";

// import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function Example() {
  // const [agreed, setAgreed] = useState(false)
  const [value, setValue] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const form = useRef();


  useEffect(() => {
    fetch('https://demo.cranesoft-digitalmenu.com/contact-form/mail')
      .then(response => response.json())
      .then(data => {
        setValue(data.Phone);
        const { FirstName, LastName, EmailAddress, Phone, Message, Company } = data;
        form.current.FirstName.value = FirstName;
        form.current.LastName.value = LastName;
        form.current.EmailAddress.value = EmailAddress;
        form.current.Phone.value = Phone;
        form.current.Message.value = Message;
        form.current.Company.value = Company;
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setFormSubmitted(true);
    
    const formData = new FormData(form.current);
    formData.forEach((value, key) => {
      console.log(key, value);
    });

    try {
      const response = await fetch('https://demo.cranesoft-digitalmenu.com/contact-form/mail', {
        method: 'POST',
        body: formData
      });
  
      if (response.ok) {
        console.log('Email sent successfully!');
      } else {
        console.error('Failed to send email.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
   
  };
  return (
   
        <div
          id="contact"
          className="isolate bg-white py-8 px-6 lg:px-8 w-full z-50 rounded-lg "
        >
          {/* <div className="mx-auto max-w-2xl text-center" id="contact">
          </div> */}
          <form
            onSubmit={handleSubmit}
            action=""
            ref={form}
            className="mx-auto mt-16 max-w-xl sm:mt-20"
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  First name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="FirstName"
                    autoComplete="given-name"
                    className="block w-full rounded-md border px-3.5 py-2 text-gray-900 shadow-sm "
                    required
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Last name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="LastName"
                    id="lastName"
                    autoComplete="family-name"
                    className="block w-full rounded-md border px-3.5 py-2 text-gray-900 shadow-sm "
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Company
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="Company"
                    autoComplete="organization"
                    className="block w-full rounded-md border px-3.5 py-2 text-gray-900 shadow-sm "
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    name="EmailAddress"
                    autoComplete="email"
                    className="block w-full rounded-md border px-3.5 py-2 text-gray-900 shadow-sm "
                    required
                  />
                </div>
              </div>
             
              <div className="sm:col-span-2">
                <label
                  htmlFor="Message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Message
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="Message"
                    id="message"
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value="Request for Demo."
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <button
                type="submit"
                className="block w-full bg-[#339C47]  px-3.5 py-2.5 text-center text-white hover:bg-green-500 "
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}

