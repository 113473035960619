import React from 'react'
import { TypeAnimation } from "react-type-animation"
import aboutus from "../../assets/aboutus.jpg"
import mission from "../../assets/mission.jpg"
import vision from "../../assets/vision.jpg"
import values from "../../assets/values.jpg"
import values2 from "../../assets/values2.jpg"
import abt from "../../assets/abt.png"
import { FaCheck } from "react-icons/fa6";
import abt2 from "../../assets/abt2.jpeg"
import { FaEye } from "react-icons/fa";
import { GoGoal } from "react-icons/go";
import { FaGem } from "react-icons/fa6";

const About = () => {
   
      
        return (
          <section>
             {/* <div className="bg-gray-100 text-black h-[150px] p-8">
      <h1 className=" text-4xl font-bold mb-4">About Us</h1>
      <p className=""><a href="/">Home {'> '}</a>About Us</p>
    </div> */}
          <div className="max-w-[100vw] relative bg-cover relative h-[70vh] md:p-24 " style={{ backgroundImage: `url(${abt2})` }}>
  <div className="absolute inset-0 flex gap-8 flex-col justify-center items-center md:max-w-[900px] p-8 md:p-2 m-auto max-w-[600px]">
    <TypeAnimation className='text-white font-bold text-center text-5xl'
      style={{  }}
      sequence={[
        'Cranesoft ',
        800,
        '',
      ]}
      repeat={Infinity}
    />
  </div>
  <div className='px-8 py-12 lg:w-[40vw] w-[80vw] md:h-auto h-48 bg-[#339C47] absolute bottom-0 right-6  transform translate-y-[50%] rounded-lg'>
    <p className='text-white font-semibold text-center md:text-2xl'>Our focus is on creating user-centered solutions that help Clients achieve their goals and drive business growth.
    </p>
  </div>
</div>



      <div className='bg-gradient-to-l from-white to-green-100 p-12'>
        <h1 className='text-5xl text-[#339C47] lg:pl-24 mt-24 text-center lg:text-left'>Who We Are</h1>
      <div className="grid lg:grid-cols-3 gap-12  justify-center px-24 pb-24 pt-8">
      
      <div className=" lg:w-auto w-[80vw] p-8 shadow-md ">
      <div className="rounded-lg bg-[#15803D]/10 p-2 ml-2 w-[42px]">
        <div className='text-2xl'><GoGoal /></div></div>
        <h3 className='text-2xl text-[#339C47] font-semibold my-4 ml-2'>Our Mission</h3>
        <p className="text-gray-800 text-xl  text-gray-800 p-2 text-left">Our Mission is to develop and implement innovative solutions that drive business growth and guarantee Customer satisfaction.</p>
        
      </div>
      <div className=" p-8 shadow-md ">
      <div className="rounded-lg bg-[#15803D]/10 p-2 w-[42px]">
        <div className='text-2xl'><FaEye /></div></div>
    <h3 className='text-2xl text-[#339C47] font-semibold my-4'>Our Vision</h3>
    <p className="text-gray-800 text-xl text-gray-800  text-left">Our Vision is to become the preferred ICT solutions provider in Nigeria and Africa, delivering excellence and innovation in everything we do.</p>
  </div>
  <div className=" p-8 shadow-md  ">
  <div className="rounded-lg bg-[#15803D]/10 p-2 w-[42px]">
        <div className='text-2xl'><FaGem /></div></div>
        <h3 className='text-2xl text-[#339C47] font-semibold my-4'>Our Values</h3>
        <p className=' text-gray-800 text-gray-800 text-xl text-left '>Innovation, Integrity, Excellence and Team Spirit.</p>
      </div>
  
  

    </div>

    </div>
      </section>
      
        )
  
}

export default About