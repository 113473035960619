
import React from 'react'
import { FaHotel } from 'react-icons/fa6'
import {
  RadialTextGradient
} from "react-text-gradients-and-animations";
import { SiGoogletagmanager } from "react-icons/si";
import { PiLinkSimpleBold } from "react-icons/pi";
import { FaFileCode } from "react-icons/fa6";
import { IoTime } from "react-icons/io5";
import { RiCustomerService2Fill } from "react-icons/ri";

const Advantages = () => {

  const Services = [
    {
      id: 1,
      icon: <SiGoogletagmanager />,
      title: 'Business Management',
      text: "This software can allow you to implement a beneficial revenue management strategy. It can help track your investments, manage your cost of sales, know the value of your stock at any time, help to redefine business focus and encourage Customer loyalty."
    },
    {
      id: 2,
      icon: <PiLinkSimpleBold />,
      title: 'Simplicity and Visiblity.',
      text: "It simplifies operations and boosts efficiency. It helps make quick, informed decisions, leading to better operations management and improved Guest satisfaction."
    },
    {
      id: 3,
      icon: <FaFileCode />,
      title: 'Accurate Reports',
      text: "It reflects your business activities in a single report. It generates several reports such as booking reports, purchase reports, stock reports, sales report and more. This software assists you to realize the overall performance of your business."
    },
    {
      id: 4,
      icon: <IoTime />,
      title: 'Save Time & Effort',
      text: "Our Hotel Management Software is designed to save you time and effort by streamlining administrative tasks. It automates booking processes, manages Guest information, and enhances communication, allowing you to focus on delivering exceptional Guest experiences."
    },
    {
      id: 5,
      icon: <RiCustomerService2Fill />,
      title: 'Support Efficiency',
      text: "We have a Team of seasoned and professionally qualified support Staff to provide you with bespoke support service."
    },
    // {
    //   id: 6,
    //   icon: <FaHotel />,
    //   title: 'Enhance Customer Experience',
    //   text: "Xain hotel management software can build a user-friendly system, and your customer can easily book their desired room through the website and can complete their payment process as well. So, it will increase your customer experience and create a positive impression for your business."
    // },
  ]

  return (
    <div className='bg-gradient-to-tl from-white to-green-100 '>
      <div className='w-[80vw] mx-auto  '>
<RadialTextGradient
      className='text-center flex items-center justify-center font-bold text-4xl pt-12 '
shape={ "circle" }
position={ "center" }
colors={[ "#15803D" ,  "#757AB1", "#EB5C67" ]}
animate={ true }
animateDirection={ "horizontal" }
animateDuration={ 6 }
textAlign="center"

>
Why You Should Use CraneSoft HMS
    
</RadialTextGradient>
  <p className='text-center text-lg font-semibold lg:w-[60%] pt-6 mx-auto '>At Cranesoft, we specialize in meeting the unique needs of the hospitality industry, offering top-tier Software Development tailored to streamline Hotel Management</p>
  <div className='py-24 w-[80vw] grid lg:grid-cols-3 justify-center gap-4'>
  {Services.map((item, index) => (
    <div key={index} className="">
      <div className="md:max-w-80 max-w-[300px]  bg-white h-96 overflow-hidden shadow-lg mb-8  transform transition-transform hover:scale-105 px-4">
        <p className="text-4xl flex items-center justify-center mt-16 rounded-lg">{item.icon}</p>
        <p className="text-xl text-center py-4 ">{item.title}</p>
        <h3 className='text-green-800 text-center font-semibold'>{item.text}</h3>
      </div>
    </div>
  ))}
</div>



</div>
    </div>
  )
}

export default Advantages