import { useState, useRef } from "react";
import { FaBars } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { Link as ScrollLink } from "react-scroll";
import cranesoft from "../assets/cranesoft.png";
import { MdFavorite } from "react-icons/md";
import { FaCommentDots } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { IoReorderFourOutline } from "react-icons/io5";
import { BiScan } from "react-icons/bi";
import { RiSecurePaymentFill } from "react-icons/ri";
import { MdShoppingCart } from "react-icons/md";
import { GiCook } from "react-icons/gi";
import { FaFileAlt } from "react-icons/fa";
import { MdInventory } from "react-icons/md";
import { MdAccountBalance } from "react-icons/md";
import { MdAccountTree } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import { MdPointOfSale } from "react-icons/md";
import { IoReorderFour } from "react-icons/io5";
import { HiComputerDesktop } from "react-icons/hi2";
import { RiComputerFill } from "react-icons/ri";
import { MdManageAccounts } from "react-icons/md";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const handleClick = () => setToggle(!toggle);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [Menu_submenu, setMenu_submenu] = useState([]);
  const subMenuTimeoutRef = useRef();
 

  const Menu = [
    {
      id: 1,
      name: "Digital menu",
      href: "/emenu",
      subMenu: [
        {
          id: 1,
          title: "Favorites Tab",
          icon: <MdFavorite size={20} color="#fff" />,
        },
        {
          id: 2,
          title: "Customer review",
          icon: <FaCommentDots size={20} color="#fff" />,
        },
        {
          id: 3,
          title: "Call a waiter",
          icon: <FaPhoneAlt size={20} color="#fff" />,
        },
        {
          id: 4,
          title: "View order",
          icon: <IoReorderFourOutline size={20} color="#fff" />,
        },
        { id: 5, title: "Easy Scan", icon: <BiScan size={20} color="#fff" /> },
        {
          id: 6,
          title: "Payment integration",
          icon: <RiSecurePaymentFill size={20} color="#fff" />,
        },
        {
          id: 7,
          title: "Online delivery",
          icon: <MdShoppingCart size={20} color="#fff" />,
        },
        { id: 8, title: "Cook time", icon: <GiCook size={20} color="#fff" /> },
        {
          id: 9,
          title: "Report management",
          icon: <FaFileAlt size={20} color="#fff" />,
        },
      ],
    },
    {
      id: 2,
      name: " Hotel-Management-Software",
      href: "/hotel-software",
      subMenu: [
        {
          id: 1,
          title: "Guest Management",
          icon: <MdFavorite size={20} color="#fff" />,
        },
        {
          id: 2,
          title: "Point of Sales",
          icon: <FaCommentDots size={20} color="#fff" />,
        },
        {
          id: 3,
          title: "Inventory",
          icon: <FaPhoneAlt size={20} color="#fff" />,
        },
        {
          id: 4,
          title: "Accounting",
          icon: <IoReorderFourOutline size={20} color="#fff" />,
        },
        { id: 5, title: "HRM", icon: <BiScan size={20} color="#fff" /> },
        
      ],
    },
    {
      id: 3,
      name: " ERP Software",
      href: "/erp-software",
      subMenu: [
        {
          id: 1,
          title: "Inventory",
          icon: <MdInventory size={20} color="#fff" />,
        },
        {
          id: 2,
          title: "Account",
          icon: <MdAccountBalance size={20} color="#fff" />,
        },
        {
          id: 3,
          title: "Manage Report",
          icon: <MdAccountTree size={20} color="#fff" />,
        },
        {
          id: 4,
          title: "Cashier Shift Report",
          icon: <BiSolidReport size={20} color="#fff" />,
        },
        {
          id: 5,
          title: "Product Sales",
          icon: <MdPointOfSale size={20} color="#fff" />,
        },
      ],
    },
    {
      id: 4,
      name: "Restaurant POS",
      href: "/respos-software",
      subMenu: [
        {
          id: 1,
          title: "Manage Order",
          icon: <IoReorderFour size={20} color="#fff" />,
        },
        {
          id: 2,
          title: "GUI POS interface",
          icon: <HiComputerDesktop size={20} color="#fff" />,
        },
        {
          id: 3,
          title: "POS waiter screen",
          icon: <RiComputerFill size={20} color="#fff" />,
        },
        {
          id: 4,
          title: "Shift Management",
          icon: <MdManageAccounts size={20} color="#fff" />,
        },
        {
          id: 9,
          title: "Report management",
          icon: <FaFileAlt size={20} color="#fff" />,
        },
      ],
    },
  ];
  

  const handleSubMenuMouseEnter = () => {
    clearTimeout(subMenuTimeoutRef.current);
    setMenu_submenu(Menu[0]?.subMenu);
    setIsSubMenuOpen(true);
  };

  const handleSubMenuMouseLeave = () => {
    subMenuTimeoutRef.current = setTimeout(() => {
      setIsSubMenuOpen(false);
    }, 300); // Adjust the delay as needed
  };

  const HoverMenu = (e) => {
    const Id = e.target.id;
    const MenuSubMenu = Menu.filter((item) => parseInt(Id) === item.id);
    setMenu_submenu(MenuSubMenu[0]?.subMenu);
  };
  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  }; 

  return (
    <div className="max-w-[100vw] h-[80px] px-4 md:px-12 border z-50 bg-white sticky top-0">
      <div className="md:max-w-[1480px] max-w-[600px] m-auto w-full h-full flex justify-between items-center md:px-0 px-4 md:p-8 z-50 sm:p-2">
        <a href="/">
          <img className="h-[25px]" src={cranesoft} alt="logo" />
        </a>

        <div className="hidden lg:flex item-center">
          <div className="flex items-center gap-8">
            <ul className="flex gap-8 font-semibold text-sm">
              <a href="/">
                <li className="cursor-pointer hover:border-b-2 hover:border-green-500">
                  Home
                </li>
              </a>
              <li
                className="cursor-pointer hover:border-b-2 hover:border-green-500"
                onMouseEnter={handleSubMenuMouseEnter}
                onMouseLeave={handleSubMenuMouseLeave}
              >
                Products
                {isSubMenuOpen && (
                  <div className="absolute bg-white pl-20 flex flex-row p-10 w-full left-0 rounded shadow-lg z-50 mt-6 border-t">
                    <div className="flex flex-col gap-7 border-r w-[30%] text-base font-bold">
                      {Menu?.map(({ id, name, href }) => (
                        <a
                          key={id}
                          id={id}
                          href={href}
                          className="block px-4 py-2 hover:text-green-600"
                          onMouseOver={HoverMenu}
                        >
                          {name}
                        </a>
                      ))}
                    </div>
                    <div className="grid grid-cols-3 gap-4 ml-5 w-[70%] px-7 font-light">
                      {Menu_submenu?.map(({ id, title, icon }) => (
                        <div
                          className="p-2 flex items-center gap-3 basis-[1/4]"
                          key={id}
                        >
                          {/* //  Icon */}
                          <div className="rounded-lg bg-[#15803D]/5 p-2 ">
                            <div className="rounded-lg bg-[#15803D] p-2">
                              {icon}
                            </div>
                          </div>
                          <div className="font-semibold capitalize text-sm">
                            <p>{title}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </li>
              <a href="/about-us">
                <li className="cursor-pointer hover:border-b-2 hover:border-green-500">
                  About Us
                </li>
              </a>

              {/* <a href="/plans">
                <li className="cursor-pointer hover:border-b-2 hover:border-green-500">
                  Plans and Pricing
                </li>
              </a> */}

              <a href="/get-started">
                <li className="cursor-pointer hover:border-b-2 hover:border-green-500">
                  Contact
                </li>
              </a>
            </ul>
          </div>

          <a href="/get-started">
            <button className="py-1 text-center px-6 mx-8 bg-[#339C47] text-white text-sm py-3">
              Get Started
            </button>
          </a>
        </div>



        <div className="lg:hidden" onClick={handleClick}>
          {toggle ? <IoCloseSharp /> : <FaBars />}
        </div>
      </div>

      <div
        className={
          toggle
            ? "bg-white z-50 absolute overflow-hidden p-4 w-full lg:hidden shadow-lg"
            : "hidden"
        }
      >
        <ul className="flex flex-col md:flex-row md:space-x-4">
      <li className="cursor-pointer p-4 hover:font-semibold hover:text-green-600">
        <a href="/">Home</a>
      </li>
      <li
        className={`cursor-pointer p-4 relative transition-all ${
          isSubMenuOpen ? 'h-auto' : ''
        }`}
        onClick={toggleSubMenu}
      >
        Products
        {isSubMenuOpen && (
          <div className="bg-white p-4 left-0 mt-1 w-full rounded shadow-lg">
            <a
              href="/emenu"
              className="block px-4 py-2 hover:text-green-600 hover:font-semibold"
            >
              Cranesoft E-menu
            </a>
            <a
              href="/hotel-software"
              className="block px-4 py-2 hover:text-green-600"
            >
              Cranesoft Hotel Software
            </a>
            <a
              href="/erp-software"
              className="block px-4 py-2 hover:text-green-600"
            >
              Cranesoft ERP Software
            </a>
            <a
              href="/respos-software"
              className="block px-4 py-2 hover:text-green-600"
            >
              Cranesoft Restaurant POS
            </a>
          </div>
        )}
      </li>
      <li className="cursor-pointer p-4 hover:font-semibold hover:text-green-600">
        <a href="/about-us">About Us</a>
      </li>
      <li className="cursor-pointer p-4 hover:font-semibold hover:text-green-600">
        <a href="/get-started">Contact</a>
      </li>
      <li className="cursor-pointer p-4 hover:font-semibold hover:text-green-600">
        <a href="/get-started">Get Started</a>
      </li>
    </ul>

      </div>
    </div>
  );
};

export default Navbar;
