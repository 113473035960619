import React, { useState } from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

function Subscribe() {
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(`Subscribing email: ${email}`);
    setEmail("");
    setFormSubmitted(true);
    emailjs
      .sendForm("service_cu3usjg", "template_7f5kqsd", form.current, {
        publicKey: "yb9-g-vK5kXTWEpBG",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          e.target.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  const htmlCode = `<div id="mc_embed_shell">
  <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
<style type="text/css">
    #mc_embed_signup{ false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 600px; margin: auto; overflow: hidden}
    #mc_embed_signup {
      // width: 100%;
      box-sizing: border-box;
      padding: 0 10px; /* Add padding to prevent edges from touching the screen */
      margin: auto;
      overflow-x: hidden;
  }
  
  .mc-field-group {
      margin-bottom: 10px; /* Add space between form fields */
  }
  
  #mc_embed_signup input[type="email"],
  #mc_embed_signup input[type="text"] {
      width: 22em;
      padding: 10px;
      border-radius: 5px;
      box-sizing: border-box; /* Include padding and border in element's total width and height */
      
  }

  
  #mc_embed_signup .button {
      box-sizing: border-box;
      background-color: rgb(22, 101, 52);
      color: white;
      border: none;
      cursor: pointer;
  }
      // width: 100%;
  
  @media (max-width: 600px) {
      #mc_embed_signup {
          width: 20%; /* Reduce the width to 90% on screens larger than 768px */
          // margin: 0 auto; 
      }
      #mc_embed_signup input[type="email"],
      #mc_embed_signup input[type="text"] {
          width: 50%;
          box-sizing: border-box; 
          
      }


      
  }
  
    /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
       We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
<form action="https://techdroidsystems.us4.list-manage.com/subscribe/post?u=6097a6ef343080f0613481a37&amp;id=49a1a66011&amp;f_id=00d1beebf0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
      <div id="mc_embed_signup_scroll" class="chip">
        <h2 style={{ f }}>Subscribe to E-menu Newsletter</h2>
        <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
        <div class="mc-field-group">
        <label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label>
        <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" value=""  required="" ></div>
        <div class="mc-field-group">
        <label for="mce-MMERGE1">First Name </label><input type="text" name="MMERGE1" class=" text" id="mce-MMERGE1" value="">
        </div>
          <div id="mce-responses" class="clear foot">
              <div class="response" id="mce-error-response" style="display: none;"></div>
              <div class="response" id="mce-success-response" style="display: none;"></div>
          </div>
<div aria-hidden="true" style="position: absolute; left: -5000px;">
    /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
    <input type="text" name="b_6097a6ef343080f0613481a37_49a1a66011" tabindex="-1" value="">
</div>
    <div class="optionalParent">
        <div class="clear foot">
            <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe">
            <p style="margin: 0px auto;"><a href="http://eepurl.com/iKrwc2" title="Mailchimp - email marketing made easy and fun"><span style="display: inline-block; background-color: transparent; border-radius: 4px;"><img class="refferal_badge" src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg" alt="Intuit Mailchimp" style="width: 220px; height: 40px; display: flex; padding: 2px 0px; justify-content: center; align-items: center;"></span></a></p>
        </div>
    </div>
</div>
</form>
</div>
<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='MMERGE1';ftypes[1]='text';fnames[2]='MMERGE2';ftypes[2]='text';fnames[3]='MMERGE3';ftypes[3]='phone';fnames[5]='MMERGE5';ftypes[5]='address';}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>`;

  return (
    <section className=" p-16 max-sm:p-2  overflow-hidden bg-[#E9F8F3] ">
      {/* <h1 className="text-4xl text-green-800 font-bold mx-auto text-center mb-8">
        Subscribe now
      </h1>

      <form
        onSubmit={handleSubmit}
        ref={form}
        className="max-w-xs mx-auto bg-[#E9F8F3] "
      >
        <label className="block mb-2 font-semibold ">Email:</label>
        <input
          type="email"
          value={email}
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          required
          className="border border-gray-300 rounded-md px-2 py-1 mb-4 w-full"
        />
        <button
          type="submit"
          className="bg-green-800 text-white px-4 py-2 rounded-md hover:bg-green-700"
        >
          Subscribe
        </button>
      </form> */} 
      <div className="max-sm:w-[92%] max-sm:m-auto max-sm:overflow-hidden max-sm:-mx-5 max-sm:px-5" >
      <div
          dangerouslySetInnerHTML={{ __html: htmlCode }}
          className="mx-auto p-2"
        />
      </div>
       
    </section>
  );
}
// bg-[#E9F8F3]

export default Subscribe;
