import React from 'react'
// import fpos from "../assets/fpos.JPG"
import foffice from "../assets/foffice.jpg";
import { FaCheck } from "react-icons/fa6";
import { motion } from "framer-motion"
import waiter from "../assets/waiter.png"
import shift from "../assets/mngshift.png"
import cpos from "../assets/pos.jpg"
import order from "../assets/ordermgt.png"


const Modules = () => {
  return (
    <section className="w-full ">

      

      <div className='bg-[#339c47] py-12'>
      <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto text-white max-w-[600px] ">
      <h1 className='text-center font-bold mb-8 text-4xl'>Order Management</h1>
      <p className='text-lg font-light mb-8 text-center'>

      Order Management is a crucial aspect of any restaurant operation, and our POS software streamlines this process for maximum efficiency. It allows you to easily take and modify orders, track order status from preparation to delivery, and manage multiple orders simultaneously. With features like order notes, and real-time order updates, you can ensure accuracy and timely service. Additionally, our software integrates seamlessly with kitchen displays and printers, reducing errors and improving communication between front-of-house and kitchen Staff.
</p>
      <div className='md:flex '>

        <div className="md:w-[50%] mb-8 px-8 mx-auto">
  <motion.ul className="text-lg font-light text-white flex flex-col items-start"
  initial={{ opacity: 0, x: 200 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 1 }}>
    <li className="flex justify-center items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Order Table</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Order List</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Track On-going Order</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Order Summary</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Waiter Sales Report</span>
    </li>
    
   
  </motion.ul>
</div>
        <motion.div 
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="md:w-[50%] items-center justify-center flex px-8 ">
          
        <img src={order} className=' rounded-lg'/>
        </motion.div>

      </div>
      </div>
      </div>
      
      
 
      < div className='bg-white py-12'>
        <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto  max-w-[600px] ">
          <h1 className='text-center font-bold mb-8 text-4xl'>GUI POS Interface</h1>
          <p className='text-lg font-light mb-8 text-center'>
Our GUI POS interface is designed to enhance the user experience for Restaurant Staff, making order management intuitive and efficient. With a user-friendly layout and interactive design, taking and managing orders becomes a seamless process. The interface provides clear visuals for menu items and order modifiers allowing staff to navigate with ease. Real-time order updates and status indicators ensure that Staff can track orders accurately and provide timely service to Customers. Overall, our GUI POS interface simplifies order management, helping Restaurants operate smoothly and deliver exceptional Customer service.</p>
          <div className='md:flex'>
  
  
  <div className="md:w-[50%] mb-8 px-8 mx-auto">
  <motion.ul className="text-lg font-light text-green flex flex-col items-start"
  initial={{ opacity: 0, x: -200 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 1 }}>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Take and Modify Orders</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>View Menu Items</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Process Payments</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>View and Manage Shift</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Split Bill</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Move/Merge Order</span>
    </li>

  </motion.ul>
</div>

  <motion.div className="md:w-[50%] items-center justify-center flex px-8 md:order-first"
   initial={{ opacity: 0, x: -200 }}
   animate={{ opacity: 1, x: 0 }}
   transition={{ duration: 0.8 }}>
    <img src={cpos} className=' w-80vw rounded-lg'/>
  </motion.div>
</div>
        </div>
      </div>

      <div className='bg-[#339c47] py-12'>
      <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto text-white max-w-[600px] ">
      <h1 className='text-center font-bold mb-8 text-4xl'>Manage Shift</h1>
      <p className='text-lg font-light mb-8 text-center'>

      The interface allows Managers to easily create, assign, and track shifts for each employee, ensuring adequate coverage during busy periods. Staff can view their assigned shifts and clock in and out directly from the POS terminal. Shift reports provide detailed insights into labor costs, employee hours, and productivity, helping managers optimize scheduling and control labor expenses. Overall, our POS software streamlines shift management, improving efficiency and ensuring smooth operations.

</p>
      <div className='md:flex '>

        <div className="md:w-[50%] mb-8 px-8 mx-auto">
  <motion.ul className="text-lg font-light text-white flex flex-col items-start"
  initial={{ opacity: 0, x: 200 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 1 }}>
    <li className="flex justify-center items-center">
      <FaCheck className="mr-2 text-white " />
      <span>View Shift</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Create Shift</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Generate Shift Report</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Monitor Attendance</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-white" />
      <span>Handle Shift Swaps</span>
    </li>
    
   
  </motion.ul>
</div>
        <motion.div 
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
        className="md:w-[50%] items-center justify-center flex px-8 ">
          
        <img src={shift} className=' rounded-lg'/>
        </motion.div>

      </div>
      </div>
      </div>
      
      < div className='bg-white py-12'>
        <div className="md:max-w-[1000px] p-4 md:p-2 mx-auto  max-w-[600px] ">
          <h1 className='text-center font-bold mb-8 text-4xl'>Waiters Screen</h1>
          <p className='text-lg font-light mb-8 text-center'>
          The Waiter's screen on our restaurant POS software is designed to optimize the workflow of your serving Staff, enhancing their efficiency and improving Customer service. It provides quick access to menu items, helping Waiters navigate the menu and place orders swiftly. Overall, our Waiter's screen simplifies order management, reduces errors, and enhances the dining experience for both Guests and Staff.
</p>
          <div className='md:flex'>
  
  
  <div className="md:w-[50%] mb-8 px-8 mx-auto">
  <motion.ul className="text-lg font-light text-green flex flex-col items-start"
  initial={{ opacity: 0, x: -200 }}
  animate={{ opacity: 1, x: 0 }}
  transition={{ duration: 1 }}>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Take and Modify Orders</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Send Orders to Kitchen</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>View Order Status</span>
    </li>
    <li className="flex items-center">
      <FaCheck className="mr-2 text-green-600" />
      <span>Clock in and out.</span>
    </li>

  </motion.ul>
</div>

  <motion.div className="md:w-[50%] items-center justify-center flex px-8 md:order-first"
   initial={{ opacity: 0, x: -200 }}
   animate={{ opacity: 1, x: 0 }}
   transition={{ duration: 0.8 }}>
    <img src={waiter} className=' w-80vw rounded-lg'/>
  </motion.div>
</div>
        </div>
      </div>

    </section>
  )

}

export default Modules