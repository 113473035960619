import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import pos from "../assets/pos.jpg"

const HeroSection = () => {
  return (
    <div className="bg-white md:h-[80vh] h-[110vh] w-[90vw] mx-auto flex items-center justify-center py-20 ">
      <div className="container mx-auto flex flex-col md:flex-row items-center gap-4 justify-center px-4">
        <div className="md:w-[60%] lg:px-16 mb-8 md:mb-0 text-center">
        <TypeAnimation
  className="text-6xl  font-bold text-[#339C47] text-center "
  sequence={['Simplified Operations: Our POS Makes It Easy', 800, '']}
  repeat={Infinity}
/>
          <p className="text-gray-600 mt-4 text-xl text-center w-[100%] ">
          Our Restaurant POS module enables seamless transactions for meals and drinks, ensuring accuracy and efficiency in every transaction. The module is equipped to handle various aspects of restaurant management; bars, lounges including order processing, and payment processing, providing a comprehensive solution for hospitality businesses.
          </p>
        </div>
        <div className="md:w-[40%]">
          <img src={pos} alt="Placeholder" className="w-full rounded-lg shadow-lg bg-transparent" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
