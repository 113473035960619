import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import erpbg from "../assets/erp.jpg"

const HeroSection = () => {
  return (
    <div className=" md:h-[80vh] w-[90vw] h-[110vh] mx-auto flex items-center justify-center py-12">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-center px-4">
        <div className="md:w-[60%] lg:mr-8 mb-8 md:mb-0 text-center py-16 px-2">
        <TypeAnimation
  className="text-6xl font-bold text-[#339C47] text-center "
  sequence={['Simplify Your Business', 800, '']}
  repeat={Infinity}
/>
          <p className="text-gray-600 mt-4 text-xl text-center ">
            Our web-based Retail Management Software is designed to enhance the day-to-day transactions of your business, increase profit, minimise expenditure and improve Customer services by increasing operational efficiency.
          </p>
        </div>
        <div className="md:w-[40%]">
          <img src={erpbg} alt="Placeholder" className="w-full rounded-lg shadow-lg bg-transparent" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;


