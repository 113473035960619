import React, {useState} from 'react'
import  heroimg from '../assets/heroimg.jfif'
import { TypeAnimation } from 'react-type-animation'
import RequestForm from "../components/RequestForm.jsx"

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;


  return (
    <div className="fixed inset-0 flex items-center justify-center z-20 mt-20">
      {/* <div className='bg-secondary p-12'></div> */}
      <div className="absolute inset-0 bg-gray-900 opacity-75" onClick={onClose}></div>
      <div className=" z-50 p-8 rounded-xl max-w-4xl sm:max-w-lg">
        <button className="absolute top-0 right-0 m-4 z-50 font-bold px-4 py-2 rounded-md right-0 m-4 bg-green-800" onClick={onClose}>Close</button> 
        {children}
      </div>
    </div>
  );
};

const Hero = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
<div className="relative h-[70vh] bg-cover bg-gray-300" style={{ backgroundImage: `url(${heroimg})` }}>
  <div className="absolute inset-0 flex flex-col justify-center items-center gap-8 p-8 md:p-2 md:max-w-[900px] m-auto max-w-[600px] text-white z-20">
    <TypeAnimation
      className="text-white font-bold text-center text-5xl z-20"
      sequence={['Cranesoft Hotel Software', 800, '']}
      repeat={Infinity}
    />
    <p className="text-gray-200 text-center text-white text-2xl z-20">
      For almost a decade, CraneSoft Hotel Management Software has been a cornerstone in the hospitality industry, offering a comprehensive and integrated platform that empowers hotels to streamline their operations with unparalleled efficiency.
    </p>
    <button onClick={openModal} className="bg-[#339C47] text-gray-200 bg-green-800 hover:bg-transparent py-2 text-center px-6 mx-auto z-20">
      Reach out for a demo
    </button>
  </div>
  <div className="absolute inset-0 bg-black opacity-50 z-0"></div>
</div>


<Modal isOpen={isModalOpen} onClose={closeModal} >
          <div className="contact-form">
          <RequestForm />
          </div>
        </Modal>
</div>

  )
}

export default Hero