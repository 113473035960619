import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home/Home";
// import GetStarted from "./pages/GetStarted";
import Emenuhome from "../src/pages/emenu/src/pages/Emenuhome";
import Hotelhome from "../src/pages/hotelsoftware/src/pages/Hotelhome"
import Erphome from "../src/pages/erp/src/pages/Erphome"
import Resposhome from "../src/pages/respos/src/pages/Resposhome"
import About from "../src/pages/About/About"
import GetStarted from "./pages/GetStarted/GetStarted";
import Pricing from "./pages/Pricing/Pricing";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/emenu",
    element: <Emenuhome/>,
  },
  {
    path: "/hotel-software",
    element: <Hotelhome/>,
  },
  {
    path: "/erp-software",
    element: <Erphome/>,
  },
  {
    path: "/respos-software",
    element: <Resposhome/>,
  },
  {
    path: "/about-us",
    element: <About />,
  },
  {
    path: "/get-started",
    element: <GetStarted />,
  },
  {
    path: "/plans",
    element: <Pricing />,
  }
 
]);
