import React from 'react'
import aboutus from '../assets/aboutus.jpg'
import { FaHotel } from "react-icons/fa6";
import {
  RadialTextGradient
} from "react-text-gradients-and-animations";
import { MdHealthAndSafety } from "react-icons/md";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { LuPlus } from "react-icons/lu";
import { FaCode } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";


const Industry = ({icon, text}) => {

    const Ind = [
  
        {
            id: 1,
            icon:  <FaHotel />,
            text: "Hospitality"
        },
  
        {
            id: 2,
            icon:  <FaMoneyCheckDollar />,
            text: "Retail"
        },
        {
          id: 3,
          icon:  <MdHealthAndSafety />,
          text: "Healthcare"
      },
        {
            id: 4,
            icon:  <LuPlus />,
            text: "Many more"
        },
    
    ]

    const Services = [
      {
        id: 1,
        icon: <FaCode />,
        title: 'Software Development',
        text: "We specialize in creating tailored software solutions designed to automate and enhance your business operations. Whether you need to improve efficiency, or innovate your workflow, we have the expertise to deliver."
      },
      {
        id: 2,
        icon: <RiCustomerService2Fill />,
        title: 'ICT Consulting',
        text: "Our ICT consulting services offer tailored solutions to streamline and optimize your business operations. From strategic planning to implementation, we provide expert guidance to leverage technology effectively."
      },
      {
        id: 3,
        icon: <LuPlus />,
        title: 'Custom Orders',
        text: "Tailored to your needs, our custom orders deliver precision and quality, ensuring every detail meets your specifications.",
        button: "Place a request",
        buttonLink:"/get-started"
      }
    ]

  return (
    <section className='border w-full'>
      {/* <div className='bg-gradient-to-r from-white to-green-100 lg:flex lg:flex-col lg:items-center lg:justify-center'>
      <RadialTextGradient
      className='text-center flex items-center justify-center font-bold text-4xl pt-12'
shape={ "circle" }
position={ "center" }
colors={[ "#15803D" ,  "#757AB1", "#EB5C67" ]}
animate={ true }
animateDirection={ "horizontal" }
animateDuration={ 6 }
textAlign="center"

>
Industries We Serve
    
</RadialTextGradient>
      <p className='text-center text-2xl mt-4'>Bridge Your Business with the Virtual World </p>
      <div className='py-24 w-[80vw] grid lg:grid-cols-4 mx-auto gap-8 '>
  {Ind.map((item, index) => (
    <div key={index} className="lg:flex p-8 border w-[200px] rounded-lg items-center justify-center mx-auto bg-white transition duration-300 ease-in-out hover:bg-[#DFFDE9]">
      <div className="border-b mx-auto flex flex-col items-center justify-center">
        <p className="text-3xl text-white border p-4 rounded-lg bg-green-700  ">
          {item.icon}
        </p>
        <h3 className=' mb-2 text-xl pt-2'>{item.text}</h3>
      </div>
    </div>
  ))}
</div>


</div> */}

<div className=' mx-auto bg-gradient-to-l from-white to-green-100 flex flex-col items-center justify-center'>
<RadialTextGradient
      className='text-center flex items-center justify-center font-bold text-4xl pt-12 '
shape={ "circle" }
position={ "center" }
colors={[ "#15803D" ,  "#757AB1", "#EB5C67" ]}
animate={ true }
animateDirection={ "horizontal" }
animateDuration={ 6 }
textAlign="center"

>
Our Services
    
</RadialTextGradient>
  <p className='text-center text-lg font-semibold lg:w-[60%] pt-6 mx-auto '>At Cranesoft, we prioritize our Clients' specific needs, crafting cutting-edge software solutions tailored to businesses of all kinds.</p>
  <div className='py-24 w-[80vw] grid lg:grid-cols-3 items-center justify-center gap-4'>
  {Services.map((item, index) => (
    <div key={index} className="md:flex p-8 border rounded-lg bg-white transition duration-300 ease-in-out hover:bg-[#DFFDE9] ">
      <div className="flex flex-col h-[300px]">
        <p className="text-4xl flex items-center justify-center border h-[70px] w-24 bg-[#339C47] text-white ">{item.icon}</p>
        {/* bg-gradient-to-tl from-[#15803D] to-[#EB5C67] */}
        <p className="text-xl py-4 ">{item.title}</p>
        <h3 className='text-green-800 font-semibold'>{item.text}</h3>
        {item.button && (
      <a href={item.buttonLink}>
        <button className='border px-4 text-white py-2 mt-2 bg-[#339C47] '>{item.button}</button>
      </a>
    )}
      </div>
    </div>
  ))}
</div>



</div>
</section>

  )
}

export default Industry