import Hero from "../components/Hero"
import Advantages from "../components/Advantages"
import Modules from "../components/Modules"
const Home = () => {
    return (
      <div>
      <Hero />
      <Advantages />
      <Modules />
      </div>
    )
  }
  
  export default Home