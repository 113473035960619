
import React from "react";
// import { logo } from "../assets";
import {
  FaFacebookF,
  FaWhatsapp,
  FaInstagram,
  FaLinkedinIn
} from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import { FaArrowUp } from "react-icons/fa";


const Footer = () => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  
      const phoneNumber = '+2348142917926'; // Your phone number
      const message = 'Hello!, My name is " " and i want to make enquiry about your E-menu'; // Optional message
    
      const handleClick = () => {
        window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
      };
  
  
  return (
    <div className="w-full bg-black px-8 py-24">
      <div className="md:max-w-[1480px] m-auto grid md:grid-cols-5 max-[780px]:grid-cols-2  gap-8 max-w-[600px] md:px-0">
        <div className="col-span-2">
          {/* <img src={logo} className="h-[25px]" /> */}
          <h1 className="text-2xl font-bold text-white py-1">Contact Us</h1>
          <h3 className="py-1 text-white">Call : +2348142918095, +2348096925209 </h3>
          <h3 className="py-1 text-white">Location: 1b, Church Street, Off Opebi Link Bridge, Opebi, Lagos, Nigeria.</h3>
          <h3 className="py-1 text-white">Email: info@techdroidsystems.com</h3>
          <div className="flex gap-4 py-4">
            <a href="https://www.facebook.com/cranesoftapp/" target="blank">
            <div className="p-4  rounded-xl">
              <FaFacebookF size={25} className="text-white" />
            </div>
            </a>
            <a href="https://ng.linkedin.com/company/cranesoft-app" target="blank">
            <div className="p-4  rounded-xl">
              <FaLinkedinIn size={25} className="text-white" />
            </div>
            </a>
            <a href="https://www.instagram.com/cranesoftapp" target="blank">
            <div className="p-4 rounded-xl">
              <FaInstagram size={25} className="text-white" />
            </div>
            </a>
          </div>
        </div>

        <div>
          <h3 className="text-2xl text-white font-bold">Explore</h3>
          <ul className="py-6 text-white">
            <a href="/"><li className="py-1 cursor-pointer">Home</li></a>
          <a href="/about-us"><li className="py-1 cursor-pointer">About Us</li></a>
          <a href="/get-started"><li className="py-1 cursor-pointer">Contact</li></a>

          </ul>
        </div>

        <div>
          <h3 className="text-2xl text-white font-bold">About Us</h3>
          <p className="py-6 text-white">
          We are a leading provider of Software Development, and ICT consultancy services. Our focus is on creating user-centered solutions that help Clients achieve their goals and drive business growth.
          </p>
        </div>

        <div className="max-[780px]:col-span-2">
          <h3 className="text-2xl text-white font-bold">Products</h3>
          <ul className="py-6 text-white">
            <a href="/emenu"><li className="py-1">E-menu</li></a>
            <a href="/hotel-software"><li className="py-1">Hotel Software</li></a>
            <a href="erp-software"><li className="py-1">ERP Software</li></a>
            <a href="respos-software"><li className="py-1">Restaurant POS</li></a>
          </ul>
        </div>
      </div>
      <div className=" fixed bottom-4 right-4 text-2xl bg-[#339C47] text-white p-4 rounded-full cursor-pointer" onClick={scrollToTop}>      <FaArrowUp className="" />
    </div>
    <button  className=" fixed bottom-4 text-2xl left-4 bg-[#339C47] text-white p-4 rounded-full cursor-pointer" onClick={handleClick}>
      <FaWhatsapp />
    </button>
    </div>
  );
};

export default Footer;
