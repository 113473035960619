import React from "react";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";
import { FaArrowDown } from "react-icons/fa";
import Cranesoftwebsite from "../assets/Cranesoftwebsite.mp4"

const HeroSection = () => {
  return (
    <section>
    <div className="lg:flex">
    <div className=" flex md:h-[80vh] h-[90vw] items-center justify-center lg:w-[50%]">
      <div className="text-center  md:max-w-[900px] px-8 md:p-2 m-auto max-w-[600px]">
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-800 mb-6">
          Creative and Intelligent Software Solutions for <br></br>Your{" "}
          <TypeAnimation
            sequence={[
              "Restaurant.",
              500,
              "Hotel.",
              500,
              "Bar.",
              500,
              "Lounge.",
              500,
              "Supermarket.",
              500,
            ]}
            style={{ fontSize: "", color: "#339C47" }}
            repeat={Infinity}
          />{" "}
        </h1>
        <a href="#products">
          <button
            className="mt-8 px-6 py-3 bg-[#339C47] text-white shadow-md hover:bg-green-600"
          >
            View Products
          </button>
        </a>
        
      </div>
    </div>
    <div className="lg:w-[40%] flex items-center w-[80%] mx-auto justify-center rounded-lg">
      <video controls className="rounded-lg">
        <source src={Cranesoftwebsite}/>


         </video>
    </div>
    </div>
    <div>
    <motion.div 
        initial="initial"
        animate="animate"
        variants={{
          initial: { y: -20 },
          animate: {
            y: 20,
            transition: {
              delay: 0.5,
              duration: 1,
              repeat: Infinity,
              repeatDelay: 0.5,
              ease: "easeInOut",
            },
          },
        }}
        className=" px-6  flex items-center justify-center text-5xl  font-semibold">
          <FaArrowDown />
        </motion.div>
    </div>
    </section>

  );
};

export default HeroSection;
