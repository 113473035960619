import React from 'react'
import { FaHotel } from 'react-icons/fa6'
import {
  RadialTextGradient
} from "react-text-gradients-and-animations";
import { MdFastForward } from "react-icons/md";
import { GiCash } from "react-icons/gi";
import { FaFileCode } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { MdSecurity } from "react-icons/md";
import { PiLinkSimpleBold } from "react-icons/pi";


const Advantages = () => {

  const Services = [
    {
      id: 1,
      icon: <MdFastForward />,
      title: 'Fast, Accurate and Efficient',
      text: "Cranesoft ERP is a fast, accurate and efficient system that can help businesses to save time and money."
    },
    {
      id: 2,
      icon: <GiCash />,
      title: 'Reduces Cost, Increasing Revenue.',
      text: "Cranesoft ERP can help businesses to reduce costs by automating tasks, streamlining processes, and improving visiblity into operations. This can lead to increased revenue as businesses are able to operate more efficiently and effectively."
    },
    {
      id: 3,
      icon: <FaFileCode />,
      title: 'Data Backup',
      text: "Cranesoft ERP automatically backs up data so businesses can always access their information in case of a disaster. The system also provides quick access to information so businesses can make decisions quickly."
    },
    {
      id: 4,
      icon: <FaFileAlt />,
      title: 'Easy Auditing and Performance Assessment Reporting.',
      text: "Cranesoft ERP provides easy auditing and performance assessment reporting. This helps businesses to track their progress and identify areas where improvement is needed."
    },
    {
      id: 5,
      icon: <MdSecurity />,
      title: 'Secure and Fraud-proof.',
      text: "Cranesoft ERP is a secure system that is protected from unauthorized access and fraud. This helps businesses to protect their data and assets."
    },
    {
      id: 6,
      icon: <PiLinkSimpleBold />,
      title: 'User-friendly.',
      text: "It is a user-friendly system that is easy to learn and use. It is also scalable, so businesses can easily add new users and modules as their needs grow."
    },
  ]

  return (
    <div className='bg-gradient-to-tl from-white to-green-100 '>
      <div className='w-[80vw] mx-auto  '>
<RadialTextGradient
      className='text-center flex items-center justify-center font-bold text-4xl pt-12 '
shape={ "circle" }
position={ "center" }
colors={[ "#15803D" ,  "#757AB1", "#EB5C67" ]}
animate={ true }
animateDirection={ "horizontal" }
animateDuration={ 6 }
textAlign="center"

>
Why You Should Use CraneSoft ERP
    
</RadialTextGradient>
  <p className='text-center text-lg font-semibold lg:w-[60%] pt-6 mx-auto '>Optimize your business operations with our ERP solution. Streamline processes and boost efficiency across your organization for enhanced productivity and growth</p>
  <div className='py-24 w-[80vw] grid lg:grid-cols-3 justify-center gap-4'>
  {Services.map((item, index) => (
    <div key={index} className="">
      <div className="md:max-w-80 max-w-[300px]  bg-white h-96 overflow-hidden shadow-lg mb-8  transform transition-transform hover:scale-105 px-4">
        <p className="text-4xl flex items-center justify-center mt-16 rounded-lg">{item.icon}</p>
        <p className="text-xl text-center py-4 ">{item.title}</p>
        <h3 className='text-green-800 text-center font-semibold'>{item.text}</h3>
      </div>
    </div>
  ))}
</div>

</div>
</div>
  )
}

export default Advantages