import { useState } from "react";
import Contact from "../../components/Contact";
import { FaCheck } from "react-icons/fa6";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;


  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-gray-900 opacity-75" onClick={onClose}></div>
      <div className=" p-8 rounded-xl  max-w-4xl sm:max-w-lg   ">
        <button className="absolute top-0 right-0 m-4 z-50 font-bold px-4 py-2 rounded-md right-0 m-4 bg-green-800" onClick={onClose}>Close</button> 
        {children}
      </div>
    </div>
  );
};


// const Pricing = () => {
//   const pricingPlans = [
//     {
//       name: "Bronze",
//       text: "For Small Restaurants, Food Vendors, Bar & Lounges",
//       priceYear: "₦50,000",
//       priceMonth: "₦5,000",
//       features: [
//         "QR code for Digital Menu",
//         "Free four(4) QR Code Stands",
//         "Access to 24 hours dedicated support line",
//         "Real-time Menu Update",
//         "Full Access to QR Creation tool",
//         "Accessibility to Customers Review",
//         "1 menu",
//       ],
//     },
//     {
//       name: "Silver",
//       text: "For Medium sized Restaurants, Hotels, Bars and Lounges",
//       priceYear: "₦120,000",
//       priceMonth: "₦10,870",
//       features: [
//         "QR code for Digital Menu",
//         "Free four(4) QR Code Stands",
//         "Access to 24 hours dedicated support line",
//         "Real-time Menu Update",
//         "Full Access to QR Creation tool",
//         "Accessibility to Customers Review",
//         "1-2 Menu",
//         "Indoor and Outdoor Ordering System",
//         "Table Identification",
//         "Sales Report",
//       ],
//     },
//     {
//       name: "Gold",
//       text: "For Medium sized Restaurants, Hotels, Bars and Lounges",
//       priceYear: "₦170,000",
//       priceMonth: "₦15,200",
//       features: [
//         "QR code for Digital Menu",
//         "Free four(4) QR Code Stands",
//         "Access to 24 hours dedicated support line",
//         "Real-time Menu Update",
//         "Full Access to QR Creation tool",
//         "Accessibility to Customers Review",
//         "1-3 Menu",
//         "Indoor and Outdoor Ordering System",
//         "Table Identification",
//         "Sales Report",
//       ],
//     },
//   ];

  
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };


  
//   return (
//     <div className="  py-12" id="pricing">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        
//         <div className="text-center">
//           <h2 className="text-4xl font-bold text-green-800">Choose a Price For Our E-menu</h2>
//           <p className="mt-2 text-lg ">
//             Select the plan that best fits your needs
//           </p>
//         </div>

//         <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8 text-center mx-auto ">
//           {pricingPlans.map((plan) => (
//             <div
//               key={plan.name}
//               className="bg-white text-center shadow-md rounded-lg mx-8 overflow-hidden"
//             >
//               <div className="px-6 py-8 flex flex-col h-full">
//                 <div>
//                   <h3 className="text-2xl font-bold text-green-800 mb-2">{plan.name}</h3>
//                   <p className="mb-4">{plan.text}</p>
//                   {/* <p className="mt-2 text-3xl">
//                     {plan.priceMonth} <span className="text-sm">/ month </span>
//                   </p>
//                   <p className="mt-2 text-green-800 text-3xl">
//                     {plan.priceYear} <span className="text-sm">/ year </span>
//                   </p> */}
                  
//                   <ul className="mt-4 space-y-2 ">
//                     {plan.features.map((feature) => (
//                       <li
//                         key={feature}
//                         className="flex items-center border-b-2 p-2"
//                       >
//                         {/* <FaCheck className="text-green-800"/> */}
//                         <span className=" text-center text-lg mx-auto text-gray-700">
//                           {feature}
//                         </span>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//                <div className="mt-auto">
//                   <button onClick={openModal} className="block w-full px-4 py-2 text-sm font-semibold text-white bg-green-800 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 my-8">Request for Pricing</button>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//         <Modal isOpen={isModalOpen} onClose={closeModal} >
//           <div className="contact-form">
//           <Contact />
//           </div>
//         </Modal>
        
//       </div>
//     </div>
//   );
// };
const Pricing = () => {
  const pricingData = [
    {
      category: "E-menu",
      plans: [
        {
          name: "Bronze",
          text: "For Small Restaurants, Food Vendors, Bar & Lounges",
          priceYear: "₦50,000",
          priceMonth: "₦5,000",
          features: [
            "QR code for Digital Menu",
            "Free four(4) QR Code Stands",
            "Access to 24 hours dedicated support line",
            "Real-time Menu Update",
            "Full Access to QR Creation tool",
            "Accessibility to Customers Review",
            "1 menu",
          ],
        },
        {
          name: "Silver",
          text: "For Small Restaurants, Food Vendors, Bar & Lounges",
          priceYear: "₦50,000",
          priceMonth: "₦5,000",
          features: [
            "QR code for Digital Menu",
            "Free four(4) QR Code Stands",
            "Access to 24 hours dedicated support line",
            "Real-time Menu Update",
            "Full Access to QR Creation tool",
            "Accessibility to Customers Review",
            "1 menu",
          ],
        },
        {
          name: "Gold",
          text: "For Small Restaurants, Food Vendors, Bar & Lounges",
          priceYear: "₦50,000",
          priceMonth: "₦5,000",
          features: [
            "QR code for Digital Menu",
            "Free four(4) QR Code Stands",
            "Access to 24 hours dedicated support line",
            "Real-time Menu Update",
            "Full Access to QR Creation tool",
            "Accessibility to Customers Review",
            "1 menu",
          ],
        },
        // Add other plans for E-menu
      ],
    },
    {
      category: "HMS",
      plans: [
        {
          name: "Bronze",
          text: "For Small-sized Restaurants, Food Vendors, Bar & Lounges",
          features: [
            "Front Office Module",
            "1 - 20 rooms",
            "1 sales point (RestPOS Module)",
            "Stock Control Module (one-off)",
            "Account Module (one-off)",
          ],
        },
        {
          name: "Silver",
          text: "For Medium-sized Restaurants, Food Vendors, Bar & Lounges",
          features: [
            "Front Office Module",
            "1 - 20 rooms",
            "Additional 1 - 20 rooms",
            "1 sales point (RestPOS Module)",
            "Additional sales point",
            "Stock Control Module (one-off)",
            "Account Module (one-off)",
          ],
        },
        {
          name: "Gold",
          text: "For Large Restaurants, Food Vendors, Bar & Lounges",
          features: [
            "On Request",
        
          ],
        },
        
      ],
    },
    {
      category: "ERP",
      plans: [
        {
          name: "Bronze",
          text: "For Small SuperMarkets, Food Vendors, Bar & Lounges",
          features: [
            "1 sales point",
            "Additional sales point",
          ],
        },
        {
          name: "Silver",
          text: "For Small Restaurants, Food Vendors, Bar & Lounges",
          features: [
            "1 sales point",
            "Additional sales point",
            "Inventory Module",
            "Accounting Module",
          ]
        },
        {
          name: "Gold",
          text: "For Small Restaurants, Food Vendors, Bar & Lounges",
     
          features: [
            "1 sales point",
            "Additional sales point",
            "Inventory Module",
            "Accounting Module",
            "Manufacturing Module",
            "Payroll & Staff Information Module",
           
          ],
        },
        
      ],
    },
    {
      category: "Restaurant POS",
      plans: [
        {
          name: "Bronze",
          text: "For Small Restaurants, Food Vendors, Bar & Lounges",
          priceYear: "₦50,000",
          priceMonth: "₦5,000",
          features: [
            "QR code for Digital Menu",
            "Free four(4) QR Code Stands",
            "Access to 24 hours dedicated support line",
            "Real-time Menu Update",
            "Full Access to QR Creation tool",
            "Accessibility to Customers Review",
            "1 menu",
          ],
        },
        {
          name: "Silver",
          text: "For Small Restaurants, Food Vendors, Bar & Lounges",
          priceYear: "₦50,000",
          priceMonth: "₦5,000",
          features: [
            "QR code for Digital Menu",
            "Free four(4) QR Code Stands",
            "Access to 24 hours dedicated support line",
            "Real-time Menu Update",
            "Full Access to QR Creation tool",
            "Accessibility to Customers Review",
            "1 menu",
          ],
        },
        {
          name: "Gold",
          text: "For Small Restaurants, Food Vendors, Bar & Lounges",
          priceYear: "₦50,000",
          priceMonth: "₦5,000",
          features: [
            "QR code for Digital Menu",
            "Free four(4) QR Code Stands",
            "Access to 24 hours dedicated support line",
            "Real-time Menu Update",
            "Full Access to QR Creation tool",
            "Accessibility to Customers Review",
            "1 menu",
          ],
        },
        
      ],
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const openModal = (plan) => {
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedPlan(null);
    setIsModalOpen(false);
  };

  return (
    <div className="py-12 border-b" id="pricing">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {pricingData.map((category) => (
          <div key={category.category} className="border-b mb-8 py-8 shadow-lg">
            <div className="text-center">
              <h2 className="text-4xl font-bold text-green-800">Choose a Price For Our {category.category}</h2>
              <p className="mt-2 text-lg">
                Select the plan that best fits your needs
              </p>
            </div>
            <div className="mt-12 grid grid-cols-1 lg:grid-cols-3 gap-8">
              {category.plans.map((plan) => (
                <div
                  key={plan.name}
                  className="bg-white text-center shadow-md rounded-lg mx-8 overflow-hidden"
                >
                  <div className="px-6 py-8 flex flex-col h-full">
                    <div>
                      <h3 className="text-2xl font-bold text-green-800 mb-2">{plan.name}</h3>
                      <p className="mb-4">{plan.text}</p>
                      <ul className="mt-4 space-y-2">
                        {plan.features.map((feature) => (
                          <li key={feature} className="flex items-center border-b-2 p-2">
                            <span className="text-center text-lg mx-auto text-gray-700">{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="mt-auto">
                      <button onClick={() => openModal(plan)} className="block w-full px-4 py-2 text-sm font-semibold text-white bg-green-800 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 my-8">Request for Pricing</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div className="contact-form">
            <Contact />
          </div>
        </Modal>
      </div>
    </div>
  );
};


export default Pricing;
