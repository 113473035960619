import React, { lazy }from 'react'
import Partners from '../../../../components/Partners'
// import { Footer, Pricing, Contact, Subscribe } from '../components'

const Hero = lazy(() => import("../components/Hero"))
const Companies = lazy(() => import("../components/Companies"))
const Advantages = lazy(() => import("../components/Advantages"))
const Features = lazy(() => import("../components/Features"))
// const Partners = lazy(() => import("../components/Partners"))
const Testimonials = lazy(() => import("../components/Testimonials"))
// const Footer = lazy(() => import("../components/Footer"))
const Pricing = lazy(() => import("../components/Pricing"))
const Contact = lazy(() => import("../components/Contact"))
const Subscribe = lazy(() => import("../components/Subscribe"))

function Home() {
  return (
    <div>
  
      <Hero />
      <Companies />
      <Advantages />
      <Features />
      <Partners backGroundColor='#E9F8F3'/>
      <Testimonials />
      <Pricing />
      {/* <Contact /> */}
      <Subscribe />
      


    </div>
  )
}

export default Home