import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div className="w-full md:p-24 py-4">
      <div className="md:max-w-[900px] p-8 md:p-2 mx-auto max-w-[600px]">
        <motion.h1
          initial={{ opacity: 0, x: -200 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center text-green-800 text-4xl mb-8 font-bold"
        >
          Cloud-based HMS for Hotels, Motels and Resorts
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, x: 200 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="text-center text-xl"
        >
          Cranesoft provides one of the top-rated Hotel Management Software (HMS)
          available today, with many customizable features that can be tailored
          to meet the specific needs of each hotel. These features include
          Reservation, Customized POS, Stock Control, Accounting Modules,
          Housekeeping checklists, Custom Reports, and much more.
        </motion.p>
      </div>
    </div>
  );
};

export default About;
